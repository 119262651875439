define("discourse/plugins/chat/discourse/services/chat-channel-info-route-origin-manager", ["exports", "@ember/service", "@ember/utils", "discourse/lib/key-value-store"], function (_exports, _service, _utils, _keyValueStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ORIGINS = _exports.INFO_ROUTE_NAMESPACE = _exports.BACK_KEY = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const BACK_KEY = _exports.BACK_KEY = "back";
  const INFO_ROUTE_NAMESPACE = _exports.INFO_ROUTE_NAMESPACE = "discourse_chat_info_route";
  const ORIGINS = _exports.ORIGINS = {
    channel: "channel",
    browse: "browse"
  };
  class ChatChannelInfoRouteOriginManager extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "store", new _keyValueStore.default(INFO_ROUTE_NAMESPACE));
    }
    get origin() {
      const origin = this.store.getObject(BACK_KEY);
      if (origin) {
        return ORIGINS[origin];
      }
    }
    set origin(value) {
      this.store.setObject({
        key: BACK_KEY,
        value
      });
    }
    get isBrowse() {
      return this.origin === ORIGINS.browse;
    }
    get isChannel() {
      return this.origin === ORIGINS.channel || (0, _utils.isEmpty)(this.origin);
    }
  }
  _exports.default = ChatChannelInfoRouteOriginManager;
});