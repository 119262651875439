define("discourse/plugins/chat/discourse/components/chat/message-creator/list-handler", ["exports", "@glimmer/component", "ember-modifier", "discourse/plugins/chat/discourse/components/chat/message-creator/lib/iterate-list", "@ember/template-factory", "@ember/component"], function (_exports, _component, _emberModifier, _iterateList, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ListHandler;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ListHandler extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "handleKeydown", (0, _emberModifier.modifier)(element1 => {
        const handler1 = event1 => {
          if (event1.key === "ArrowDown") {
            event1.preventDefault();
            event1.stopPropagation();
            this.args.onHighlight((0, _iterateList.getNext)(this.args.items, this.args.highlightedItem));
          } else if (event1.key === "ArrowUp") {
            event1.preventDefault();
            event1.stopPropagation();
            this.args.onHighlight((0, _iterateList.getPrevious)(this.args.items, this.args.highlightedItem));
          } else if (event1.key === "Enter" && this.args.highlightedItem) {
            event1.preventDefault();
            event1.stopPropagation();
            if (event1.shiftKey && this.args.onShifSelect) {
              this.args.onShifSelect(this.args.highlightedItem);
            } else {
              this.args.onSelect(this.args.highlightedItem);
            }
          }
        };
        element1.addEventListener("keydown", handler1);
        return () => {
          element1.removeEventListener("keydown", handler1);
        };
      }));
    }
  }
  _exports.default = ListHandler;
  _ListHandler = ListHandler;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <span style="display: contents" {{this.handleKeydown}} ...attributes>
        {{yield}}
      </span>
    
  */
  {
    "id": "D4sxaCLN",
    "block": "[[[1,\"\\n    \"],[11,1],[24,5,\"display: contents\"],[17,1],[4,[30,0,[\"handleKeydown\"]],null,null],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/list-handler.js",
    "isStrictMode": true
  }), _ListHandler);
});