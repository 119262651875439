define("discourse/plugins/chat/discourse/components/chat/footer/unread-indicator", ["exports", "@glimmer/component", "@ember/service", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _component, _service, _templateFactory, _component2, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UnreadThreadsIndicator = _exports.UnreadDirectMessagesIndicator = _exports.UnreadChannelsIndicator = void 0;
  var _class, _descriptor, _FooterUnreadIndicator;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const CHANNELS_TAB = "channels";
  const DMS_TAB = "dms";
  const THREADS_TAB = "threads";
  const MAX_UNREAD_COUNT = 99;
  const UnreadChannelsIndicator = _exports.UnreadChannelsIndicator = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FooterUnreadIndicator @badgeType={{CHANNELS_TAB}} />
  
  */
  {
    "id": "a+meD7JI",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeType\"],[[32,1]]],null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "scope": () => [FooterUnreadIndicator, CHANNELS_TAB],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  const UnreadDirectMessagesIndicator = _exports.UnreadDirectMessagesIndicator = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FooterUnreadIndicator @badgeType={{DMS_TAB}} />
  
  */
  {
    "id": "a+meD7JI",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeType\"],[[32,1]]],null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "scope": () => [FooterUnreadIndicator, DMS_TAB],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  const UnreadThreadsIndicator = _exports.UnreadThreadsIndicator = (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <FooterUnreadIndicator @badgeType={{THREADS_TAB}} />
  
  */
  {
    "id": "a+meD7JI",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badgeType\"],[[32,1]]],null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "scope": () => [FooterUnreadIndicator, THREADS_TAB],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  let FooterUnreadIndicator = _exports.default = (_class = (_FooterUnreadIndicator = class FooterUnreadIndicator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatTrackingStateManager", _descriptor, this);
      _defineProperty(this, "badgeType", this.args.badgeType);
    }
    get urgentCount() {
      if (this.badgeType === CHANNELS_TAB) {
        return this.chatTrackingStateManager.publicChannelMentionCount;
      } else if (this.badgeType === DMS_TAB) {
        return this.chatTrackingStateManager.directMessageUnreadCount;
      } else {
        return 0;
      }
    }
    get unreadCount() {
      if (this.badgeType === CHANNELS_TAB) {
        return this.chatTrackingStateManager.publicChannelUnreadCount;
      } else if (this.badgeType === THREADS_TAB) {
        return this.chatTrackingStateManager.hasUnreadThreads ? 1 : 0;
      } else {
        return 0;
      }
    }
    get showUrgent() {
      return this.urgentCount > 0;
    }
    get showUnread() {
      return this.unreadCount > 0;
    }
    get urgentBadgeCount() {
      let totalCount1 = this.urgentCount;
      return totalCount1 > MAX_UNREAD_COUNT ? `${MAX_UNREAD_COUNT}+` : totalCount1;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.showUrgent}}
        <div class="chat-channel-unread-indicator -urgent">
          <div class="chat-channel-unread-indicator__number">
            {{this.urgentBadgeCount}}
          </div>
        </div>
      {{else if this.showUnread}}
        <div class="chat-channel-unread-indicator"></div>
      {{/if}}
    
  */
  {
    "id": "nfrZ+lqq",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUrgent\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-unread-indicator -urgent\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-channel-unread-indicator__number\"],[12],[1,\"\\n          \"],[1,[30,0,[\"urgentBadgeCount\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showUnread\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-channel-unread-indicator\"],[12],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/footer/unread-indicator.js",
    "isStrictMode": true
  }), _FooterUnreadIndicator), _FooterUnreadIndicator), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatTrackingStateManager", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});