define("discourse/plugins/chat/discourse/components/user-threads/preview", ["exports", "@glimmer/component", "discourse/helpers/format-date", "@ember/template-factory", "@ember/component"], function (_exports, _component, _formatDate, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ThreadPreview;
  class ThreadPreview extends _component.default {
    get lastReplyDate() {
      return (0, _formatDate.default)(this.args.preview.lastReplyCreatedAt, {
        leaveAgo: true
      });
    }
  }
  _exports.default = ThreadPreview;
  _ThreadPreview = ThreadPreview;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <span class="chat-message-thread-indicator__last-reply-timestamp">
        {{this.lastReplyDate}}
      </span>
      <div class="c-user-thread__excerpt">
        <div class="c-user-thread__excerpt-poster">
          {{@preview.lastReplyUser.username}}
        </div>
        <span>:</span>
        <span class="c-user-thread__excerpt-text">
          {{@preview.lastReplyExcerpt}}
        </span>
      </div>
    
  */
  {
    "id": "pzCCMbNM",
    "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-timestamp\"],[12],[1,\"\\n      \"],[1,[30,0,[\"lastReplyDate\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"c-user-thread__excerpt\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"c-user-thread__excerpt-poster\"],[12],[1,\"\\n        \"],[1,[30,1,[\"lastReplyUser\",\"username\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,\":\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"c-user-thread__excerpt-text\"],[12],[1,\"\\n        \"],[1,[30,1,[\"lastReplyExcerpt\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@preview\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/user-threads/preview.js",
    "isStrictMode": true
  }), _ThreadPreview);
});