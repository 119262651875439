define("discourse/plugins/chat/discourse/services/chat-channel-composer", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "discourse/lib/body-scroll-lock"], function (_exports, _tracking, _object, _runloop, _service, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelComposer = _exports.default = (_dec = (0, _service.service)("chat-thread-composer"), (_class = class ChatChannelComposer extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "threadComposer", _descriptor5, this);
      _initializerDefineProperty(this, "loadingSlider", _descriptor6, this);
      _initializerDefineProperty(this, "capabilities", _descriptor7, this);
      _initializerDefineProperty(this, "appEvents", _descriptor8, this);
      _initializerDefineProperty(this, "site", _descriptor9, this);
      _initializerDefineProperty(this, "textarea", _descriptor10, this);
      _initializerDefineProperty(this, "scroller", _descriptor11, this);
    }
    init() {
      super.init(...arguments);
      this.appEvents.on("discourse:focus-changed", this, this.blur);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("discourse:focus-changed", this, this.blur);
    }
    focus() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.textarea?.focus(options);
      (0, _runloop.schedule)("afterRender", () => {
        if (this.capabilities.isIOS && !this.capabilities.isIpadOS) {
          (0, _bodyScrollLock.disableBodyScroll)(this.scroller, {
            reverse: true
          });
        }
      });
    }
    blur() {
      this.textarea.blur();
    }
    edit(message) {
      this.chat.activeMessage = null;
      message.editing = true;
      message.channel.draft = message;
      if (this.site.desktopView) {
        this.focus({
          refreshHeight: true,
          ensureAtEnd: true
        });
      }
    }
    async replyTo(message) {
      this.chat.activeMessage = null;
      if (message.channel.threadingEnabled) {
        if (!message.thread?.id) {
          try {
            this.loadingSlider.transitionStarted();
            const threadObject = await this.chatApi.createThread(message.channel.id, message.id);
            message.thread = message.channel.threadsManager.add(message.channel, threadObject);
          } finally {
            this.loadingSlider.transitionEnded();
          }
        }
        message.channel.resetDraft(this.currentUser);
        await this.router.transitionTo("chat.channel.thread", ...message.thread.routeModels);
        this.threadComposer.focus({
          ensureAtEnd: true,
          refreshHeight: true
        });
      } else {
        message.channel.draft.inReplyTo = message;
        this.focus({
          ensureAtEnd: true,
          refreshHeight: true
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "threadComposer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadingSlider", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "textarea", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "scroller", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "focus", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replyTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "replyTo"), _class.prototype)), _class));
});