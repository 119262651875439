define("discourse/plugins/chat/discourse/models/user-chat-thread-membership", ["exports", "@glimmer/tracking", "discourse/lib/notification-levels"], function (_exports, _tracking, _notificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserChatThreadMembership = _exports.default = (_class = class UserChatThreadMembership {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new UserChatThreadMembership(args);
    }
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      _initializerDefineProperty(this, "lastReadMessageId", _descriptor, this);
      _initializerDefineProperty(this, "notificationLevel", _descriptor2, this);
      _initializerDefineProperty(this, "threadTitlePromptSeen", _descriptor3, this);
      this.lastReadMessageId = args.last_read_message_id;
      this.notificationLevel = args.notification_level;
      this.threadTitlePromptSeen = args.thread_title_prompt_seen;
    }
    get isQuiet() {
      return this.notificationLevel === _notificationLevels.NotificationLevels.REGULAR || this.notificationLevel === _notificationLevels.NotificationLevels.MUTED;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "lastReadMessageId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notificationLevel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "threadTitlePromptSeen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
});