define("discourse/plugins/chat/discourse/components/chat-message-thread-indicator", ["exports", "@glimmer/component", "@ember/routing", "@ember/template", "discourse/helpers/format-date", "discourse/helpers/replace-emoji", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/chat-thread-participants", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _routing, _template, _formatDate, _replaceEmoji, _i18n, _chatThreadParticipants, _chatUserAvatar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ChatMessageThreadIndicator;
  class ChatMessageThreadIndicator extends _component.default {
    get interactiveUser() {
      return this.args.interactiveUser ?? true;
    }
    get threadMessageRoute() {
      return [...this.args.message.thread.routeModels, this.args.message.thread.preview.lastReplyId];
    }
  }
  _exports.default = ChatMessageThreadIndicator;
  _ChatMessageThreadIndicator = ChatMessageThreadIndicator;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <LinkTo
        class="chat-message-thread-indicator"
        @route="chat.channel.thread.near-message"
        @models={{this.threadMessageRoute}}
        title={{i18n "chat.threads.open"}}
        tabindex="0"
        ...attributes
      >
        <div class="chat-message-thread-indicator__last-reply-avatar">
          <ChatUserAvatar
            @user={{@message.thread.preview.lastReplyUser}}
            @avatarSize="small"
            @interactive={{this.interactiveUser}}
          />
        </div>
  
        <div class="chat-message-thread-indicator__last-reply-info">
          <span class="chat-message-thread-indicator__last-reply-username">
            {{@message.thread.preview.lastReplyUser.username}}
          </span>
          <span class="chat-message-thread-indicator__last-reply-timestamp">
            {{formatDate
              @message.thread.preview.lastReplyCreatedAt
              leaveAgo="true"
            }}
          </span>
        </div>
        <div class="chat-message-thread-indicator__replies-count">
          {{i18n "chat.thread.replies" count=@message.thread.preview.replyCount}}
        </div>
        <ChatThreadParticipants @thread={{@message.thread}} />
        <div class="chat-message-thread-indicator__last-reply-excerpt">
          {{replaceEmoji (htmlSafe @message.thread.preview.lastReplyExcerpt)}}
        </div>
      </LinkTo>
    
  */
  {
    "id": "Gc6fstT2",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"chat-message-thread-indicator\"],[16,\"title\",[28,[32,1],[\"chat.threads.open\"],null]],[24,\"tabindex\",\"0\"],[17,1]],[[\"@route\",\"@models\"],[\"chat.channel.thread.near-message\",[30,0,[\"threadMessageRoute\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-avatar\"],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@user\",\"@avatarSize\",\"@interactive\"],[[30,2,[\"thread\",\"preview\",\"lastReplyUser\"]],\"small\",[30,0,[\"interactiveUser\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-info\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-username\"],[12],[1,\"\\n          \"],[1,[30,2,[\"thread\",\"preview\",\"lastReplyUser\",\"username\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-timestamp\"],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,2,[\"thread\",\"preview\",\"lastReplyCreatedAt\"]]],[[\"leaveAgo\"],[\"true\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__replies-count\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"chat.thread.replies\"],[[\"count\"],[[30,2,[\"thread\",\"preview\",\"replyCount\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[32,4],null,[[\"@thread\"],[[30,2,[\"thread\"]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-excerpt\"],[12],[1,\"\\n        \"],[1,[28,[32,5],[[28,[32,6],[[30,2,[\"thread\",\"preview\",\"lastReplyExcerpt\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@message\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-thread-indicator.js",
    "scope": () => [_routing.LinkTo, _i18n.default, _chatUserAvatar.default, _formatDate.default, _chatThreadParticipants.default, _replaceEmoji.default, _template.htmlSafe],
    "isStrictMode": true
  }), _ChatMessageThreadIndicator);
});