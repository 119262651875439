define("discourse/plugins/chat/discourse/components/chat/message/left-gutter", ["exports", "@glimmer/component", "@ember/helper", "@ember/routing", "@ember/service", "truth-helpers", "discourse/components/bookmark-icon", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/helpers/format-chat-date", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _routing, _service, _truthHelpers, _bookmarkIcon, _dIcon, _formatChatDate, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ChatMessageLeftGutter;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessageLeftGutter = _exports.default = (_class = (_ChatMessageLeftGutter = class ChatMessageLeftGutter extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-left-gutter">
        {{#if @message.reviewableId}}
          <LinkTo
            @route="review.show"
            @model={{@message.reviewableId}}
            class="chat-message-left-gutter__flag"
          >
            {{dIcon "flag" title="chat.flagged"}}
          </LinkTo>
        {{else if (eq @message.userFlagStatus 0)}}
          <div class="chat-message-left-gutter__flag">
            {{dIcon "flag" title="chat.you_flagged"}}
          </div>
        {{else if this.site.desktopView}}
          <span class="chat-message-left-gutter__date">
            {{formatChatDate
              @message
              (hash mode="tiny" threadContext=@threadContext)
            }}
          </span>
        {{/if}}
        {{#if @message.bookmark}}
          <span class="chat-message-left-gutter__bookmark">
            <BookmarkIcon @bookmark={{@message.bookmark}} />
          </span>
        {{/if}}
      </div>
    
  */
  {
    "id": "8/IIAcEI",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-left-gutter\"],[12],[1,\"\\n\"],[41,[30,1,[\"reviewableId\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"chat-message-left-gutter__flag\"]],[[\"@route\",\"@model\"],[\"review.show\",[30,1,[\"reviewableId\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[32,1],[\"flag\"],[[\"title\"],[\"chat.flagged\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[28,[32,2],[[30,1,[\"userFlagStatus\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"chat-message-left-gutter__flag\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"flag\"],[[\"title\"],[\"chat.you_flagged\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-left-gutter__date\"],[12],[1,\"\\n          \"],[1,[28,[32,3],[[30,1],[28,[32,4],null,[[\"mode\",\"threadContext\"],[\"tiny\",[30,2]]]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]]],[41,[30,1,[\"bookmark\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-left-gutter__bookmark\"],[12],[1,\"\\n          \"],[8,[32,5],null,[[\"@bookmark\"],[[30,1,[\"bookmark\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@message\",\"@threadContext\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message/left-gutter.js",
    "scope": () => [_routing.LinkTo, _dIcon.default, _truthHelpers.eq, _formatChatDate.default, _helper.hash, _bookmarkIcon.default],
    "isStrictMode": true
  }), _ChatMessageLeftGutter), _ChatMessageLeftGutter), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});