define("discourse/plugins/chat/discourse/lib/chat-message-flag", ["exports", "@ember/application", "@ember/service", "discourse/lib/ajax-error", "discourse-common/lib/get-url", "discourse-i18n"], function (_exports, _application, _service, _ajaxError, _getUrl, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessageFlag = _exports.default = (_class = class ChatMessageFlag {
    constructor(owner) {
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      (0, _application.setOwner)(this, owner);
    }
    title() {
      return "flagging.title";
    }
    customSubmitLabel() {
      return "flagging.notify_action";
    }
    submitLabel() {
      return "chat.flagging.action";
    }
    targetsTopic() {
      return false;
    }
    editable() {
      return false;
    }
    _rewriteFlagDescriptions(flags) {
      return flags.map(flag => {
        flag.set("description", _discourseI18n.default.t(`chat.flags.${flag.name_key}`, {
          basePath: (0, _getUrl.default)("")
        }));
        return flag;
      });
    }
    flagsAvailable(flagModal) {
      let flagsAvailable = flagModal.site.flagTypes;
      flagsAvailable = flagsAvailable.filter(flag => {
        return flagModal.args.model.flagModel.availableFlags.includes(flag.name_key);
      });

      // "message user" option should be at the top
      const notifyUserIndex = flagsAvailable.indexOf(flagsAvailable.filterBy("name_key", "notify_user")[0]);
      if (notifyUserIndex !== -1) {
        const notifyUser = flagsAvailable[notifyUserIndex];
        flagsAvailable.splice(notifyUserIndex, 1);
        flagsAvailable.splice(0, 0, notifyUser);
      }
      return this._rewriteFlagDescriptions(flagsAvailable);
    }
    async create(flagModal, opts) {
      flagModal.args.closeModal();
      const channelId = flagModal.args.model.flagModel.channel.id;
      const messageId = flagModal.args.model.flagModel.id;
      try {
        await this.chatApi.flagMessage(channelId, messageId, {
          flag_type_id: flagModal.selected.id,
          message: opts.message,
          is_warning: opts.isWarning,
          take_action: opts.takeAction,
          queue_for_review: opts.queue_for_review
        });
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});