define("discourse/plugins/chat/discourse/components/chat/message-creator/group", ["exports", "@glimmer/component", "@ember/service", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _dIcon, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _ChatableGroup;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatableGroup = _exports.default = (_class = (_ChatableGroup = class ChatableGroup extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _defineProperty(this, "group_with_too_many_members", _discourseI18n.default.t("chat.new_message_modal.group_with_too_many_members", {
        membersCount: this.args.item.model.chat_enabled_user_count
      }));
    }
    get isDisabled() {
      if (!this.args.membersCount) {
        return !this.args.item.enabled;
      }
      return this.args.membersCount + this.args.item.model.chat_enabled_user_count > this.siteSettings.chat_max_direct_message_users;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="chat-message-creator__chatable -group"
        data-disabled={{this.isDisabled}}
      >
        <div class="chat-message-creator__group-icon">
          {{icon "user-friends"}}
        </div>
        <div class="chat-message-creator__group-name">
          {{@item.model.name}}
        </div>
  
        {{#if this.isDisabled}}
          <span class="chat-message-creator__disabled-warning">
            {{this.group_with_too_many_members}}
          </span>
        {{/if}}
      </div>
    
  */
  {
    "id": "QSWddn4T",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -group\"],[15,\"data-disabled\",[30,0,[\"isDisabled\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__group-icon\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"user-friends\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__group-name\"],[12],[1,\"\\n        \"],[1,[30,1,[\"model\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isDisabled\"]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-creator__disabled-warning\"],[12],[1,\"\\n          \"],[1,[30,0,[\"group_with_too_many_members\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/group.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _ChatableGroup), _ChatableGroup), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});